import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import { Button } from "primereact/button";
import TitleH2 from "components/common/TitleH2";
import TitleH1 from "components/common/TitleH1";
import { useHistory, useParams } from "react-router";
import PDFReport from "components/common/PDFReport";
import XLSReport from "../../common/XLSReport";
import { InputText } from "primereact/inputtext";
import OkInputText from "../../common/OkInputText";
import PDFSentimentReport from "../../common/PDFSentimentReport";

const ClientDetailPage = ({ uiStore, breadcrumbsStore, clientStore, mainStore }) => {

    let history = useHistory();

    const { entity } = clientStore;
    const { title, enabled } = entity;
    const { id } = useParams();

    useEffect(() => {
        uiStore.setPageProperty({
            page: 'clients',
            className: 'clients'
        });

        breadcrumbsStore.addItem({
            linkName: 'Клиенты',
            link: '/clients',
        }, 0);

        breadcrumbsStore.addItem({
            linkName: title,
            link: `/clients/${entity.id}/detail`,
        }, 1);


    }, [entity]);

    useEffect(() => {
        clientStore.loadEntity(id)
            .then(() => {
                mainStore.setClient(clientStore.entity)
            });
    }, [])


     return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    active={enabled}
                    ico={'bag_gradient'}
                    showBadge={true}
                    showInfo={false}
                    color={'color-blue'}
                >
                    {title}
                </TitleH1>
            </div>
            <div className={cn('form-container')} style={{ paddingBottom: 0 }}>
                <div className={cn('edit-form')}>
                    <div className={cn('buttons-container', 'buttons-container__position_top')}>
                        <div className={cn('button-container')}>
                            <Button
                                className={cn('p-button-outlined')}
                                label="Редактировать"
                                icon="pi pi-pencil"
                                onClick={() => {
                                    history.push(`/clients/${entity.id}/edit`);
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">
                                <span className="p-float-label">

                                <InputText
                                    id='technicalAccount'
                                    name='technicalAccount'
                                    type='text'
                                    value={entity.technicalAccount}
                                    disabled
                                />
                                <label htmlFor="technicalAccount">Технический аккаунт парсинга</label>

                            </span>
                            </div>
                        </div>
                        <div className={cn('edit-form__col')}>
                            <div className="p-field">

                                <span className="p-float-label">
                                    <OkInputText
                                        id='reviewCollectorTemplate'
                                        name='reviewCollectorTemplate'
                                        type='text'
                                        value={entity.reviewCollectorTemplate || ''}
                                        disabled
                                    />
                                        <label htmlFor="reviewCollectorTemplate">Название в ссылке сбора отзыва</label>
                                    </span>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className={cn('detail-container')}>
                <div className={cn('detail')}>
                    <div className={cn('title-container')}>
                        <TitleH2
                            count={entity.counters.companies}
                            color='color-blue-light2'
                            ico='house2_gradient'
                            href={`/clients/${entity.id}/companies`}
                        >
                            Компании
                        </TitleH2>
                    </div>

                    <div className={cn('title-container')}>
                        <TitleH2
                            count={entity.counters.branches}
                            color='color-lilac'
                            ico='house_gradient'
                            href={`/clients/${entity.id}/branches`}
                        >
                            Филиалы
                        </TitleH2>
                    </div>
                    <div className={cn('title-container')}>
                        <TitleH2
                            count={entity.counters.integrations}
                            color='color-orange'
                            ico='chain_gradient'
                            href={`/clients/${entity.id}/integrations`}
                        >
                            Интеграции
                        </TitleH2>
                    </div>
                    <div className={cn('title-container')}>
                        <TitleH2
                            count={entity.counters.users}
                            color='color-blue-light'
                            ico='avatar__blue_gradient'
                            href={`/clients/${entity.id}/users`}
                        >
                            Пользователи
                        </TitleH2>
                    </div>
                </div>
            </div>
            <div className={cn('additional-container')}>
                <div className={cn('detail')}>
                    <TitleH2
                        ico='gear'
                    >
                        Дополнительные действия
                    </TitleH2>
                        <PDFReport clientId={id}/>
                        <PDFSentimentReport clientId={id}/>
                        <XLSReport clientId={id}/>
                </div>
            </div>
        </div>
    )
}

ClientDetailPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    clientStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'clientStore', 'mainStore')(observer(ClientDetailPage));

