import { inject, observer } from "mobx-react";
import React, { forwardRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';

registerLocale('ru', ru)
import cn from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "styles/calendar.scss";

const PDFReport = inject('uiStore', 'clientStore')(observer(({ uiStore, clientStore, clientId }) => {
    const [period, setPeriod] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);

    // eslint-disable-next-line react/prop-types,react/display-name
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
        const label = value ?? 'Выбор месяца';
        return (

            <Button
                className={cn('no-hover')}
                label={label}
                icon="pi pi-calendar"
                onClick={onClick}
                ref={ref}
            />
        )
    });

    const renderFooter = () => {
        return (
            <div className={'clients'}>
                <div className={cn('confirmation-footer', 'pdf-confirmation')}>
                    <Button
                        label="Получить на почту"
                        className={cn('p-button-outlined')}
                        onClick={() => {
                            setLoading(true)
                            clientStore.onDownloadPdf(clientId, {
                                year: moment(period).format("YYYY").toString(),
                                month: moment(period).format("M").toString(),
                            })
                                .then((result) => {
                                    if (result.task) {
                                        setLoading(false)
                                        setDisplayConfirmation(false)

                                        uiStore.setMessage(
                                            {
                                                severity: 'success',
                                                summary: '',
                                                detail: 'Отчет будет отправлен на почту',
                                                sticky: false,
                                            }
                                        );
                                    }
                                });
                        }}
                        icon="pi pi-check"
                    />
                    <Button
                        label="Отмена"
                        icon="pi pi-times"
                        className={cn('p-button-outlined')}
                        onClick={() => {
                            setDisplayConfirmation(false)
                        }}
                    />
                </div>
            </div>
        );
    }
    return (
        <>
            <div>
                <div className={cn('form-container', 'pb-0')}>
                    <div className={cn('edit-form__row')}>
                        <div className={cn('edit-form__col', 'edit-form__col_title')}>
                            <h3>Сгенерировать PDF отчет: &quot;Общий срез репутации компании&quot; за период:</h3>
                        </div>
                        <div className={cn('edit-form__col')} style={{ display: "flex", alignItems: "center" }}>
                            <div  className={cn('report-button__wrapper')}>
                                <DatePicker
                                    selected={period}
                                    onChange={(date) => setPeriod(date)}
                                    dateFormat="MM.yyyy"
                                    showMonthYearPicker
                                    customInput={<ExampleCustomInput/>}
                                    calendarClassName="OKReviewCalendar"
                                    locale="ru"
                                />
                                <Button
                                    className={cn('p-button-outlined')}
                                    label="Скачать"
                                    icon="pi pi-download"
                                    loading={loading}
                                    onClick={() => {
                                        setDisplayConfirmation(true)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    header="Внимание"
                    visible={displayConfirmation}
                    modal
                    footer={renderFooter()}
                    onHide={() => {

                        setDisplayConfirmation(false)
                    }}>
                    <div className="confirmation-content">
                        <span>
                            PDF отчет будет сформирован и направлен Вам в течение нескольких минут на эл. почту.
                        </span>
                    </div>
                </Dialog>
            </div>
        </>
    );

}));

export default PDFReport;
